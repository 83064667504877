<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<div class="field grid">
					<div class="col-2 md:col-1">
						<FileUpload mode="basic" name="address[]" :customUpload="true" :auto="true"
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
							:maxFileSize="10000000" @upload="onUpload" @uploader="packageUploader" />
					</div>
					<div class="col-2 md:col-2">
						<h5>{{file?.name}}</h5>
					</div>
					<div class="col-2 md:col-2">
						<Dropdown id="sheet" v-model="selectedSheet" :options="sheets" @change="onChangeSheet" placeholder="Select a sheet" />
					</div>
				</div>
				<!-- <div class="field grid">
					<label for="option" class="col-1 mb-2 md:col-1 md:mb-0">{{$t('address.options')}}</label>
					<div class="col-1 md:col-1 p-inputgroup">
                <span class="p-inputgroup-addon">Header</span>
                <InputNumber v-model="options.header" @blur="(event) => onBlur('header', event)" placeholder="Header" />
				</div>
							<div class="col-2 md:col-2 p-inputgroup">
					<span class="p-inputgroup-addon">S.C</span>
					<InputNumber v-model="range.s.c" @blur="(event) => onBlur('sc', event)" placeholder="Start Column" />
				</div>
							<div class="col-2 md:col-2 p-inputgroup">
					<span class="p-inputgroup-addon">E.C</span>
					<InputNumber v-model="range.e.c" @blur="(event) => onBlur('ec', event)" placeholder="End Column" />
				</div>
							<div class="col-2 md:col-2 p-inputgroup">
					<span class="p-inputgroup-addon">S.R</span>
					<InputNumber v-model="range.s.r" @blur="(event) => onBlur('sr', event)" placeholder="Start Row" />
				</div>
							<div class="col-2 md:col-2 p-inputgroup">
					<span class="p-inputgroup-addon">E.R</span>
					<InputNumber v-model="range.e.r" @blur="(event) => onBlur('er', event)" placeholder="End Row" />
				</div>
				</div> 
			-->
				<div class="field grid">
					<label for="type" class="col-1 mb-2 md:col-1 md:mb-0">{{$t('partner.name')}}</label>
					<div class="col-2 md:col-2">
						<Dropdown id="partnerCode" 
							v-model="infos.partnerCode" 
							@change="onChangePartner"
							:options="partners" 
							optionLabel="label" 
							optionValue="name" 
							:placeholder="$t('partner.select')" />
					</div>
					<label for="type" class="col-1 mb-2 md:col-1 md:mb-0">{{$t('shipment.select')}}</label>
					<div class="col-2 md:col-2">
						<Dropdown id="shipmentCode" 
							v-model="infos.shipmentCode" 
							:options="shipments" 
							optionLabel="shipmentCode" 
							optionValue="shipmentCode"
							:placeholder="$t('shipment.select')" />
					</div>
					<!--
					<div class="col-12 md:col-12 mt-2 col-offset-1 grid">
						<div class="col-1 md:col-1">
							<Button :label="$t('button.read')" @click="onRead($event)"></Button>
						</div>
						<div class="col-1 md:col-1">
							<Button :label="$t('button.upload')" @click="onUpload($event)"></Button>
						</div>
					</div>
					-->
				</div>
				<CrudTable ref="packageList" :entities="sheetData"
					:meta="metaTable"
					@tableAction="onTableAction"
					@itemAction="onItemAction"/>
				<!--
					<dialog-confirm :visible="deleteEntityDialog"
						:header="$t('user.confirm_delete')"
						content = "Dialog content"
						@hide="deleteEntityDialog = false"
						@reject="deleteEntityDialog = false" @accept="deleteEntity"
					>
						<template #content>
							<div class="flex align-items-center justify-content-center">
								<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
								<span v-if="currentEntity">Are you sure you want to delete <b>{{currentEntity.email}}</b>?</span>
							</div>
						</template>
					</dialog-confirm>
				-->
			</div>
		</div>
		<Dialog v-model:visible="showProgressSpinner" modal :closable="false" :style="{ width: '50vw' }">
			<ProgressSpinner style="width: 50px; height: 50px">
			</ProgressSpinner>
		</Dialog>
	</div>

</template>

<script>
//https://github.com/SheetJS/sheetjs/blob/master/demos/vue/modify/src/App.vue
import {FilterMatchMode} from 'primevue/api';
import AddressService from '@/service/AddressService'
import PackageService from '@/service/PackageService'
import PartnerService from '@/service/PartnerService'
import ShipmentService from '@/service/ShipmentService'
import * as XLSX from 'xlsx'
import hash from 'object-hash';
//import DialogConfirm from '@/pages/common/DialogConfirm'
import CrudTable from '@/pages/common/CrudTable'
//import { XlsxJson, XlsxRead, XlsxTable, XlsxSheets, XlsxSheet} from 'vue3-xlsx'

export default {
	components: { CrudTable },
	data() {
		return {
			file: null,
			showProgressSpinner: false,
			options: { header: 2 }, //Set header to 2 indicates that the sheetdata store as map with first row is the header
			range: {s: {}, e: {}},
			workbook: null,
			sheets: [],
			selectedSheet: null,
			sheetData: null,
			sheetName: null,
			partners: [],
			shipments: [],
			infos: {},
			filters: {},
			metaTable: {
					loading: false,
					entityName: "package",
					expander: false,
					searchable: true,
					paginator:{
						enable: true,
						rows: 10,
						currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} packages",
					},
					columns: [],
					tableActions: [
						/*
						{
							name: "read",
							icon: "pi pi-eye",
							style: "p-button-rounded mr-2"
						},
						*/
						{
							name: "upload",
							icon: "pi pi-upload",
							style: "p-button-rounded mr-2"
						}
					],
					itemActions: []
			}
		}
	},
	watch: {
		range(newValue, oldValue) {
			this._readSheet();
			console.log(newValue);
			console.log(oldValue);
		}
	},
	created() {
		this.initFilters();
	},
  mounted() {
    PartnerService.getAll().then(res => {
        this.partners = res.filter(item => item.partnerType == 'agent').map(partner => ({
          name: partner.code,
          label: partner.code
        }));
      });
  },
	methods: {
		onTableAction(sAction) {
			if(sAction == 'read') {
				this._readSheet();
			} else if (sAction == 'upload') {
				this._upload();
			}
		},
		onItemAction(sAction, entity, index) {
			if (sAction =='save') {
				if (entity.id) {
					AddressService.update(entity).then(response => {
						this.entities[index] = response.data
					});
				} else {
					let data = {...entity};
					AddressService.create(data).then(response => {
						this.entities[index] = {mode:'edit'};
						this.entities.push(response.data);
					});
				}
			} else if (sAction == 'edit') {
				entity.mode = 'edit';
			} else if (sAction == 'delete') {
				AddressService.delete(entity.id).then(() => {
					this.entities.splice(index, 1);
				});
			}
		},
		async packageUploader(event) {
			this.file = event.files ? event.files[0] : null;
			const buffer = await this.file.arrayBuffer();
			const data = XLSX.read(buffer, this.option);
			this.sheets = data.SheetNames;
			this.selectedSheet = this.sheets.length > 0? this.sheets[0] : null;
			this.workbook = data.Sheets;
			this._readSheet();
		},
		onChangeSheet(event) {
			this.selectedSheet = event.value;
			this._readSheet();
		},
		async onChangePartner(event) {
			this.shipments = await ShipmentService.getAgentShipments(event.value);
			if (this.shipments.length == 1) {
				this.infos.shipmentCode = this.shipments[0].shipmentCode;
			}
			this._readSheet();
		},
		_readSheet() {
			if (this.workbook && this.selectedSheet) {
			switch (this.infos.partnerCode) {
			case 'V':
			case 'v':
				this._parsePackageFromPartnerV()
				break;
			case 'H':
			case 'h':
				this._parsePackageFromPartnerH()
				break;
			}
				}
			},
		_parsePackageFromPartnerH() {
			this.metaTable.loading = true;
			const sheet = this.workbook[this.selectedSheet];
			const awb = sheet['A6'].v.split(':');
			if (awb.length > 1) {
				this.infos.awbCode = awb[1];
			}
			const sheetRange = XLSX.utils.decode_range(sheet["!ref"]);
			this.options['header'] = 0;
			this.options['range'] = {
				s: {r: 12, c: 0},
				e: {r: sheetRange.e.r, c: sheetRange.e.c}
			};
			this.sheetData = XLSX.utils.sheet_to_json(sheet, this.options);
			if(Array.isArray(this.sheetData) && this.sheetData.length > 0) {
				const columns = Object.keys(this.sheetData[0]);
				this.metaTable.columns = columns.map(col => ({
					name: col,
					label: col
				}));
			}
			this.metaTable.loading = false;
		},
		_parsePackageFromPartnerV() {
			this.metaTable.loading = true;
			const sheet = this.workbook[this.selectedSheet];
			const sheetRange = XLSX.utils.decode_range(sheet["!ref"]);
			this.options['range'] = sheetRange;
			this.options['header'] = 0;
			this.sheetData = XLSX.utils.sheet_to_json(sheet, this.options);
			if(Array.isArray(this.sheetData) && this.sheetData.length > 0) {
				const columns = Object.keys(this.sheetData[0]);
				this.metaTable.columns = columns.map(col => ({
					label: col,
					name: col
				}))
			}
			this.metaTable.loading = false;
		},
		onRead() {
			this._readSheet();
		},
		onBlur(field, event) {
			const value = parseInt(event.value.replace(/,/g,''));
			let bRead = false;
			if (field == 'header' && value != this.options.header) {
				this.options.header = value;
				bRead = true;
			} else if (field == 'sc' && value != this.range.s.c) {
				this.range.s.c = value;
				bRead = true;
			} else if (field == 'sr' && value != this.range.s.r) {
				this.range.s.r = value;
				bRead = true;
			} else if (field == 'ec' && value != this.range.e.c) {
				this.range.e.c = value;
				bRead = true
			} else if (field == 'er' && value != this.range.e.r) {
				this.range.e.r = value;
				bRead = true;
			}
			if (bRead) {
				this._readSheet();
			}
		},
		onUpload() {
			this._upload();
		},
		_cleanData() {
			if (Array.isArray(this.sheetData) && this.sheetData.length > 0) {
				const columns = this.metaTable.columns.map(col => col.name);
				let rowData = [], mapSet=new Set();
				for (let r = 0; r < this.sheetData.length; r++) {
					const rowValue = this.sheetData[r];
					if (rowValue[columns[0]] == null || rowValue[columns[0]] == undefined) {
						continue;
					}					
					let row = [];
					for(let i = 0; i < columns.length; i++) {
						row.push(rowValue[columns[i]]);
					}
					const rowHash = hash(row);
					if (!mapSet.has(rowHash)) {
						mapSet.add(rowHash)
						rowData.push(row);
					}
				}
				return rowData;
			}
		},
		_upload() {
			const rawData = this._cleanData();
			if (Array.isArray(rawData) && rawData.length > 0) {
				this.showProgressSpinner = true;
				PackageService.import(this.infos, rawData).then(res => {
					console.log(res);
					this.showProgressSpinner = false;
					this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('address.imported'), life: 3000});
				}).catch((e) => {
					console.log(e);
					this.showProgressSpinner = false;
				});
			}
		},
		onParsed(sheets) {
			this.sheets = sheets;
			if (Array.isArray(sheets) && sheets.length > 0) {
				this.selectedSheet = sheets[0];
			}
		},
		onJsonParsed(data) {
			console.log(data);
			this.sheetData = data;
		},
		saveEntity(entity) {
			if (entity.email.trim()) {
				if (entity.id) {
					this.entities[this.findIndexById(entity.id)] = entity;
						AddressService.update(entity.id, entity).then((response) => {
							console.log(response);
							this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.updated'), life: 3000});
							this.currentEntity = response.data;
							this.entityDialog = false;
						})
				}
				else {
					AddressService.create(entity).then((response) => {
						this.currentEntity = response.data;
						this.entities.push(response.data);
						this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.created'), life: 3000});
						this.entityDialog = false;
					})
				}
				this.selectedEntity = {};
			}
		},
		editEntity(entity) {
			this.currentEntity = {...entity};
			this.entityDialog = true;
		},
		openConfirmDialog() {
			this.$confirm.require({
				message: 'Are you sure you want to proceed?',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					//callback to execute when user confirms the action
				},
				reject: () => {}
			});
		},
		confirmDeleteEntity(entity) {
			this.currentEntity = entity;
			this.deleteEntityDialog = true;
		},
		deleteEntity() {
			this.entities = this.entities.filter(val => val.id !== this.currentEntity.id);
			this.deleteEntityDialog = false;
			this.currentEntity = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.deleted'), life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.entities.length; i++) {
				if (this.entities[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteEntitiesDialog = true;
		},
		deleteSelectedEntities() {
			this.entities = this.entities.filter(val => !this.selectedEntities.includes(val));
			this.deleteEntitiesDialog = false;
			this.selectedEntities = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.multiple_deleted'), life: 3000});
		},
		initFilters() {
        this.filters = {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
    }
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
</style>
